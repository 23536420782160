export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLE = 'GET_ARTICLE';
export const SET_CURRENT_ARTICLE = 'SET_CURRENT_ARTICLE';
export const SET_ARTICLE = 'SET_ARTICLE';
export const GET_ARTICLES_ERROR = 'GET_ARTICLES_ERROR';
export const SAVE_ARTICLE_ERROR = 'SAVE_ARTICLE_ERROR';
export const SEARCH_INC = 'SEARCH_INC';
export const CLEAR_SEARCH_INC = 'CLEAR_SEARCH_INC';
export const INIT_APPLICATION = 'INIT_APPLICATION';
export const SET_SEARCH_MODE = 'SET_SEARCH_MODE';
export const ADD_STORED = 'ADD_STORED';
export const REMOVE_STORED = 'REMOVE_STORED';
export const REMOVE_CHILD = 'REMOVE_CHILD';
export const REMOVE_ARTICLE = 'REMOVE_ARTICLE';
export const REMOVE_PARENT = 'REMOVE_PARENT';
export const ADD_CHILDS = 'ADD_CHILDS';
export const NEW_ARTICLE = 'NEW_ARTICLE';
export const SWITCH_MODE = 'SWITCH_MODE';
export const UPDATE_CHILD = 'UPDATE_CHILD';
export const TOGGLE_SEARCH_LIST = 'TOGGLE_SEARCH_LIST';


export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';









