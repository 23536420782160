import React, {useReducer, createContext} from 'react'
import ArticleReducer from './reducer'
import * as action from '../types'

const ArticleContext = createContext();

// state component used in App.js
export const ArticleStateProvider = (props) => {

    const emptyArticle = {  

        "art_no" : 0,
        "shortname" : "",
        "name" : "",
        "title" : "Nowy Artykuł",
        "summary" : "",
        "description" : ">",
        "write_list" : ["damii"],
        "read_list" : ["all"],
        "childs": []
    };

    //[] = useState(..)

    //return promise!
    // const getArticles = async () => {

    //     try {
    //         const res = await axios.get('/api/articles')
    //         dispatch({
    //             type: action.GET_ARTICLES,
    //             payloa: res.data
    //         })

    //     } catch (err) {
    //         dispatch({
    //             type: action.GET_ARTICLES_ERROR,
    //             payload: "GET_ARTICLES_ERROR" //err.response.msg
    //         })
    //     }
    // }

    const initAppl = (arts) => {
    
        dispatch({
            type: action.INIT_APPLICATION,
            payload: {articles: arts, initArticleId: arts.length ? arts[0]._id : 0}
        })
    }

    //return promise
    // const addArticle = async () => {

    //     try {
        
    //         return await axios.post(`/api/articles/add`, emptyArticle, {headers: {'Content-Type': 'application/json'}});
        
    //     } catch (err) {
                
    //         dispatch({
    //             type: action.SAVE_ARTICLE_ERROR,
    //             payload: "newArticle: SAVE_ARTICLE_ERROR" //err.response
    //         })
    //     }
    //     return [];
    // }

    const newArticle = (article) => {

        dispatch({
            type: action.NEW_ARTICLE,
            payload: article 
        })
    }


    const getArticle = (articleId) => { // używane w Article.js, ustawia currentArticle wg article id

        dispatch({
            type: action.GET_ARTICLE,
            payload: articleId
        })
    }


    const setCurrentArticle = (article) => {


        // fist update UI! (user can't wait, for refresh)
        dispatch({  
            type: action.SET_CURRENT_ARTICLE,   // set currentArticle
            payload: article
        })         
        // const res = await axios.post(`/api/articles/update/${article._id}`, article, config)
        // getArticles();
    }
    

    const setArticle = (article) => {


        // fist update UI! (user can't wait, for refresh)
        dispatch({  
            type: action.SET_ARTICLE,   // set currentArticle, update articles
            payload: article
        })         
        // const res = await axios.post(`/api/articles/update/${article._id}`, article, config)
        // getArticles();
    }
    const searchInc = ( searchStr ) => {

        dispatch({
            type: action.SEARCH_INC,
            payload: searchStr
        })
    }


    const setSearchMode = ( mode ) => {

        dispatch({
            type: action.SET_SEARCH_MODE,
            payload: mode
        })
    }  


    const clearSearchInc = () => {

        dispatch({
            type: action.CLEAR_SEARCH_INC,
        })
    }


    const addStored = (articleId) => {

        dispatch({
            type: action.ADD_STORED,
            payload: articleId
        })
    }


    const removeStored = (articleId) => {
        
        dispatch({
            type: action.REMOVE_STORED,
            payload: articleId
        })
    }


    const removeChild = (articleId) => {
        
        dispatch({
            type: action.REMOVE_CHILD,
            payload: articleId
        })
    }


    const removeArticle = (articleId) => {
        
        dispatch({
            type: action.REMOVE_ARTICLE,
            payload: articleId
        })
    }


    const removeParent = (articleId) => {
        
        dispatch({
            type: action.REMOVE_PARENT,
            payload: articleId
        })
    }


    const addChilds = (childs) => {

        dispatch({
            type: action.ADD_CHILDS,
            payload: childs
        })  
    }


    const setChildType = (child) => {

        dispatch({
            type: action.UPDATE_CHILD,
            payload: child
        })  
    }
    

    const switchViewMode = () => {

        dispatch( { type: action.SWITCH_MODE })
    }


    const toggleSearchList = () => {

        dispatch( { type: action.TOGGLE_SEARCH_LIST })
    }

    const [state, dispatch] = useReducer(ArticleReducer, {

        articles: [], //[emptyArticle],
        currentArticle: [],
        groups: [{name:"example", articles: []}],
        searchArticles: [],
        storedArticles: [],
        searchMode: "childs",
        //searchTxt: "",
        viewMode: true,
        showSearchList: false
    });

    return (
        <ArticleContext.Provider value = {{
            
                articles: state.articles,
                currentArticle: state.currentArticle,   // direct access to current article, 
                searchArticles: state.searchArticles,
                groups: state.groups,
                searchMode: state.searchMode,
                //searchTxt: state.searchTxt,
                storedArticles: state.storedArticles,
                viewMode: state.viewMode,
                showSearchList: state.showSearchList, // not used
                emptyArticle,
                searchInc,
                clearSearchInc,
                setSearchMode,
                getArticle,
	        setArticle,
                setCurrentArticle,
                initAppl,
                addStored,
                removeStored,
                removeChild,
                removeArticle,
                removeParent,
                addChilds,
                newArticle,
                switchViewMode,
                setChildType,
                toggleSearchList // not used
            }}>
            {props.children}
        </ArticleContext.Provider>
    )
}

export default ArticleContext;
