import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import axios from 'axios'

import ArticleContext from '../../context/article/context'
import EditorBase from '../forms/EditorBase'
import ArticlesList from '../forms/ArticlesList'
import {SRow, appStyle} from '../style'

//    background-image: ${appStyle.czubajGradient};
const HomeContainer = styled(Container)`


    background-color: #888;
    padding: 0;
    max-width: -webkit-fill-available;
`
//  background-image: ${appStyle.czubajGradient};
const EditorCol = styled(Col)`


  background-color: #888;
  display: inline-flex;
  flex-direction: column; 
  padding-left: 2px;
  padding-right: 2px;
  overflow: auto;
  height: 88vh;
`
//  background-color: ${appStyle.background};
const RightCol = styled(Col)`

  display: inline-flex;
  flex-direction: column; 
  padding-left: 2px;
  padding-right: 2px;
  background-color: #888;
  overflow: auto;
  height: 'fit-content';
  margin-bottom: 20px;
`




const Article = withRouter(({match}) => {


    const context = useContext(ArticleContext)
    const {
        articles,
        getArticle,
        initAppl
    } = context;
    


    console.log("Article: component will mount");

    let getArticles = () => {

      console.log("Article: getArticles: axios get..");
      (async () => await axios.get( '/api/articles' ))().then(result => {
              
        console.log(`          getArticles - initAppl:: ${result.data.length} articles.`); //JSON.stringify( result.data )
        initAppl( result.data );
      })
      .catch(err => {
          
          alert("          Application initaialize error: " + JSON.stringify(err.message))
      });   
    }

   


    
    useEffect(() => {

     console.log("Article: init");	    
     if(articles.length === 0) {
	     
	getArticles();
     }
     return () => { console.log("Article: component will umount"); };
 
    }, []);
    
     useEffect(() => {

        if(articles.length === 0) getArticles();         
        console.log("Article: match.params.id changed:: " + match.params.id);

        getArticle(match.params.id); // <-- no articles yet!

        // return () => {
        //     cleanup
        // };
     }, [match.params.id, articles.length]);


    return (
    
      <HomeContainer className="go_top_when_horizontal">
        <SRow>
          <RightCol md={3}>
            <ArticlesList/>
          </RightCol>
          <EditorCol md={9}>
            <EditorBase id = {match.params.id}/>
          </EditorCol>

        </SRow>        
      </HomeContainer> 
    );
});
export default Article;
