import React, {useReducer, createContext} from 'react'
import AuthReducer from './reducer'
import axios from 'axios'

import * as action from '../types'

import setAuthToken from '../../utils/authtoken'

const AuthContext = createContext()

export const AuthStateProvider = (props) => {

  const intialState = {

    token: localStorage.getItem('token'),
    isAuthencated: null,
    loading: true,
    user: null,
    error: []
  }

  const [state, dispatch] = useReducer(AuthReducer, intialState)

  // Load User

  const loadUser = async () => {

    if (localStorage.token) setAuthToken(localStorage.token);
    try {

      const res = await axios.get('/api/auth')
      dispatch({ type: action.USER_LOADED, payload: res.data })
    } catch (err) {

      dispatch({type: action.AUTH_ERROR})
    }
  }

  //Register User
  const register = async formData => {

    const config = { header: { 'Content-Type': 'application/json' }  }
    try {
        
      const res = await axios.post("/api/register", formData, config)
      dispatch({ type: action.REGISTER_SUCCESS, payload: res.data })
      loadUser();
    } catch (err) {

      dispatch({ type: action.REGISTER_FAIL, payload: err.response.data.error })
    }
  }

  //login user

  const login = async formData => {

    const config = { header: { 'Content-Type': 'application/json'} }
    try {

      const res = await axios.post('/api/auth', formData, config);
      dispatch({ type: action.LOGIN_SUCCESS, payload: res.data })
      loadUser();
    } catch (err) {
      console.log("login err:" + JSON.stringify(err.response.data) )
      dispatch({ type: action.LOGIN_FAIL, payload: err.response.data.errors ? err.response.data.errors.map(e=>e.msg) : err.response.data.msg})
    }
  }

  const setError = (err) => {

    dispatch({type: action.REGISTER_FAIL, payload: [{ msg: err }] })
  }

  // Logout
  const logout = () => dispatch({ type: action.LOGOUT });

  // Clear Errors
  const clearErrors = () => dispatch({ type: action.CLEAR_ERRORS });
  return (
    <AuthContext.Provider value={{
      token: state.token,
      isAuthencated: state.isAuthencated,
      user: state.user,
      error: state.error,
      loading: state.loading,
      register,
      login,
      loadUser,
      logout,
      clearErrors,
      setError
    }} >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContext;