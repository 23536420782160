import * as action from '../types'
  
  export default (state, { type, payload }) => {

    switch (type) {

      case action.USER_LOADED:
        
        return {
          ...state,
          isAuthencated: true,
          user: payload,
          loading: false,
          error: []
        }

      case action.REGISTER_SUCCESS:
      case action.LOGIN_SUCCESS:
        console.log(`action:LOGIN_SUCCESS:${JSON.stringify(payload)}`)
        localStorage.setItem('token', payload.token)
        return {
          ...state,
          ...payload,
          isAuthencated: true,
          loading: false,
          error: []
        }

      case action.REGISTER_FAIL:
      case action.LOGIN_FAIL:
      case action.AUTH_ERROR:
      case action.LOGOUT:
        localStorage.removeItem('token')
        console.log("to error:" + JSON.stringify(payload) )
        return {
          ...state,
          token: null,
          isAuthencated: null,
          name: null,
          email: null,
          user: null,
          loading: false,
          error: payload ? payload : []
        }

      case action.CLEAR_ERRORS:
        return {
          ...state,
          error: []
        }
        
      default:
        return state
    }
  }