import React, { useContext, useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ArticleContext from '../../context/article/context'
import {SInputFormControl, SRow, appStyle } from '../style'



const NavigatorLink = styled(Link)`

  font-family: "Crete Round";
  font-weight: normal;
  font-size: 1.3rem;
  border: 0;
  padding: 0;
  margin: 0;
  margin-right: 1rem;
  background-color: ${appStyle.background2};
//   border: 2px solid #c0a000;
//   border-radius: ${appStyle.appRadius};
  color: #bbbb30;
  text-shadow: -1px -1px 2px rgba(0,0,0,0.3);
  text-align: left;
  width: -webkit-fill-available;
  white-space: nowrap;

  :hover {

      background-color:  rgba(0, 0, 0, 0.0);
      text-shadow: 4px 4px 8px  rgba(255,255,0,0.67);
      color: #ffff70;
  } 
`;

const SNavigatorRow = styled(SRow)`

    background-color: rgba(0,0,0,0.2);
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0;
    padding: 0;
`

// display: inline-flex;
// flex-direction: row;
const SParentListGroup = styled(ListGroup)`

    padding:0;
    border: 0;    
    margin: 0;
    text-align: left;
    display: inline-flex;
    flex-direction: row;
`;


export const STitleInputFormControl = styled(SInputFormControl)`

    font-size: ${appStyle.titleFontSize};
    font-family: "Crete Round";
    font-weight: normal;
    height: 1.8rem;
    opacity: 1;
    color: #ffffff;
    background-color: #5e508afa;
`;



const Navigator = () => {

  const context = useContext(ArticleContext)
  const {
        viewMode,
        articles,
        currentArticle,
        setCurrentArticle,

  } = context;




  // component did mount
  useEffect(() => {

        // return () => {
        //   console.log('will unmount');
        // }
  }, [])


  const OnArticleTitleChange = ( e ) => {
      
    console.log( e.target.value )
    setCurrentArticle({...currentArticle[0], title: e.target.value}) // <--- tu powinna być zmiana tylko curr_title!
  } 

  return (

    <SNavigatorRow>
    <STitleInputFormControl 
      readOnly = {viewMode} 
      type = 'text' 
      value = {currentArticle.length ? currentArticle[0].title : "?"} 
      onChange = {OnArticleTitleChange} 
      placeholder = 'Title'
    />
    <i className="fas fa-arrow-left" style= {{"color": "yellow", "margin":"0.25rem 1.6rem"}}></i>
    <SParentListGroup horizontal='md'> { 
                    
        articles.filter(art => {

            let res = art.childs.filter(child => currentArticle.length && child.id === currentArticle[0]._id)
            return res.length; // true (length!=0) for articles with childs
        }).map(article => (  // for every article with child
          <NavigatorLink to={`/articles/${article._id}`}>{article.title}</NavigatorLink>
        ))
    }
    </SParentListGroup>
    </SNavigatorRow>  
  )
}

export default Navigator;
