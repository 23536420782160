import * as action from '../types'


// Takes state and action, return new state
export default (state, { type, payload }) => {

    let res = state.currentArticle.length > 0 ? state.currentArticle[0] : null  // <--- to będzie curr_childs

    switch (type) {

        case action.GET_ARTICLES:
            return {
                ...state,
                articles: payload,
                error: null
            }



        case action.GET_ARTICLE:
            //console.log("articles:" + JSON.stringify(state.articles))
            res = state.articles.filter(art => art._id === payload)            
            console.log("current article ---> :" + JSON.stringify(res.length ? res[0] : {description:"not found", _id:0}))

            return {
                ...state,
                //articles: [...state.articles, payload]
                currentArticle: res.length ? [res[0]] : [] // {description:"not found", _id:0}]
                //currentArticle rozbić na poszczególne pola:
                // curr_art_no: res.art_no,
                // curr_shortname: res.shortname,
                // curr_name: res.name,
                // curr_title: res.title,
                // curr_summary: res.summary,
                // curr_description: res.description,
                // curr_write_list: res.write_list,
                // curr_read_list: res.read_list,
                // curr_childs: res.childs,
            }



        case action.NEW_ARTICLE:
            return {
                ...state,
                articles: [...state.articles, payload],
                //currentArticle: payload
            }
        


        case action.GET_ARTICLES_ERROR:
            return {

                ...state,
                error: payload,
            }

        case action.SET_ARTICLE: // not used yet, part removed from SET_CURRENT_ARTICLE

	    return {
                ...state,
                articles: state.articles.map(art => art._id === payload._id ? payload : art)
	 }

        case action.SET_CURRENT_ARTICLE: // to musi być rozbine na SET_ARTICLE_TITLE, SET_ARTICLE_DESCRIPTION, SET_ARTICLE_SUMMARY, SET_ARTICLE_CHILDS
           // console.log("payload:" + payload.name)
            return {
                ...state,
                //articles: state.articles.map(art => art._id === payload._id ? payload : art),
                currentArticle: [payload]
                //currentArticle rozbić na poszczególne pola:
                // curr_art_no: payload.art_no,
                // curr_shortname: payload.shortname,
                // curr_name: payload.name,
                // curr_title: payload.title,
                // curr_summary: payload.summary,
                // curr_description: payload.description,
                // curr_write_list: payload.write_list,
                // curr_read_list: payload.read_list,
                // curr_childs: payload.childs,
            }

        case action.SAVE_ARTICLE_ERROR:
            return {

                ...state,
                error: payload,
            }

        case action.SEARCH_INC:
            const regex = new RegExp(`${payload}`, 'gi')
            return {
                ...state,
                //searchTxt: payload,
                searchArticles: state.articles.filter(article => 

                    article.title.match( regex )
                ).map(art => { 

                    return {_id:art._id, title: art.title}; 
                })
            }

        case action.CLEAR_SEARCH_INC:
            return {
                ...state,
                searchArticles: state.articles.map(art => { 
                    return {_id:art._id, title: art.title}; 
                })
            }

        case action.SET_SEARCH_MODE:
            return {
                ...state,
                searchMode: payload
            }

        case action.INIT_APPLICATION:

            if(payload && payload.articles.length) {

                //res = payload.articles.filter(article => article._id === payload.initArticleId)
                res = payload.articles.filter(article => article.summary === '#main')
                let unassigned = payload.articles.filter(article => article.summary === '#unassigned');
                if(unassigned.length && unassigned[0]) {

                    unassigned[0].childs = payload.articles.filter(article => {

                        // checkout if article is child of some other article
                        let parents = payload.articles.filter(parent => {

                            return parent.childs.reduce((isParent, child) => isParent || child.id === article._id, false)
                        });
                        return parents.length === 0 && article.summary !== "#main";
                    }).map(article => ({type: "", id: article._id}));
                }
                return {
                    ...state,
                    articles: payload.articles,
                    searchArticles: payload.articles,
                    currentArticle: state.currentArticle.length ? state.currentArticle : res.length ? [res[0]] : [{}],    // commented!!!
                    //currentArticle rozbić na poszczególne pola:
                    // curr_art_no: res.art_no,
                    // curr_shortname: res.shortname,
                    // curr_name: res.name,
                    // curr_title: res.title,
                    // curr_summary: res.summary,
                    // curr_description: res.description,
                    // curr_write_list: res.write_list,
                    // curr_read_list: res.read_list,
                    // curr_childs: res.childs,
                    storedArticles: [],
                    error: null
                }
            }
            else {

                console.log("action.INIT_APPLICATION: no any articles found!")
                return state;
            }

        case action.ADD_STORED:
            return {
                ...state,
                storedArticles: [payload, ...(state.storedArticles)]
            }

        case action.REMOVE_STORED:
            return {
                ...state,
                storedArticles: state.storedArticles.filter(articleId => articleId !== payload)
            }

        case action.REMOVE_ARTICLE:
            return {
                ...state
            }

        case action.REMOVE_PARENT:
            return {
                ...state
            }

        case action.ADD_CHILDS: 
            res = state.currentArticle.length > 0 ? state.currentArticle[0] : null  // <--- to będzie curr_childs
            
            let errorMessage = "";
            if(res === null) {

                errorMessage = "No current article!";
            }
            else if(payload.filter(child => child.id === res._id).length > 0) {  // <--- curr_id

                errorMessage = "Can't add this articles as childs - some article is also a parent!";
            }
            else if(res.childs.filter(child => payload.filter(pChild=> pChild.id === child.id ).length > 0).length > 0) {

                errorMessage = "Can't add this articles as childs - some article already found on child list!";
            }

            if(errorMessage.length) {

                alert(errorMessage);
                return state;
            }
            else {
              
                res.childs = [...res.childs, ...payload]
                return {
                    ...state,
                    articles: state.articles.map(art => { return art._id === res._id ? res : art }),  // <--- curr_id
                }
            }

        case action.REMOVE_CHILD:
            res = state.currentArticle.length > 0 ? state.currentArticle[0] : null  // <--- to będzie curr_childs
            
            if(res != null) {

                res.childs = res.childs.filter(child => child.id !== payload)  // <--- curr_childs
                return {
                    ...state,
                    articles: state.articles.map(art => { return art._id === res._id ? res : art }),
                }   
            }

            alert("No current article!");           
            return state;
  

        case action.UPDATE_CHILD: 

            res = state.currentArticle.length > 0 ? state.currentArticle[0] : null  // <--- to będzie curr_childs
            
            if(res != null) {

                res.childs = res.childs.map(child => (  // <--- curr_childs
                    child.id === payload.id ? 
                        { id: child.id, 
                        type: child.type === "" ? payload.type : 
                                child.type === "LINK-PART" && payload.type === "LINK" ? "PART" :
                                child.type === "LINK-PART" && payload.type === "PART" ? "LINK" :
                                child.type === "LINK" && payload.type === "LINK" ? "" :
                                child.type === "LINK" && payload.type === "PART" ? "LINK-PART" :
                                child.type === "PART" && payload.type === "LINK" ? "LINK-PART" :
                                child.type === "PART" && payload.type === "PART" ? "" : 
                                "" // unrecognized?
                        } 
                        : child
                ))
                return {
                    ...state,
                    articles: state.articles.map(art => { return art._id === res._id ? res : art }),
                }   
            }

            alert("No current article!");           
            return state;



        case action.SWITCH_MODE:
            return {
                ...state,
                viewMode: !state.viewMode
            }

        case action.TOGGLE_SEARCH_LIST: // not used
            return {
                ...state,
                showSearchList: !state.showSearchList
            }

        default:
            return state;
    }
}
