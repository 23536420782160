import React, { useContext, useState } from 'react'
import axios from 'axios'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Select from "react-select";
import { useHistory } from "react-router-dom";

import {SRow, SCol, SInputContainer} from '../style'
import ArticleContext from '../../context/article/context'
import AuthContext from '../../context/auth/context'
import Navigator from './Navigator'

import styled from 'styled-components';

//background-image: linear-gradient(to right, #BCE072, #505050, #303030);
//rgb(216 78 47)
const SNavbar = styled(Navbar)`


  background-color: rgb(88,68,138);
  margin-bottom: 0;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.57);
  width: -webkit-fill-available;
  height: 140px;
`;

export const LogoTitle = styled.div`

    font-family: 'Specjal Elite', serif;
    font-size: 2.2rem;
    margin: 0 0.24rem;
    line-height: 2rem;
`;



export const LogoComment = styled.p`
    font-size: 0.75rem;
    margin-top:0.6rem;
    color:#ddd
`;

// navigator buttons
export const NavLink = styled(Nav.Link)`

    list-style: none;
    padding: 0.0rem 0.5rem;    
    transition: all 0.3s ease 0s;
    color: white;
    display:  -webkit-inline-box;
   
    :hover {
        color: white;
        opacity: 0.5;
        cursor: pointer;
    }
`;



    // padding-top: 0px;
    // padding-buttom: 0px;
    // display: inline-flex;
    // flex-direction: column;   
    //  margin-top: 0px;
    // margin-buttom: 0px;  
    //    border: 0px; 
    //    
    // text-align: left;
    // cursor: pointer;      


    export const MySelect = styled(Select)`
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    width: 24rem;
    hight: 1rem;
  
`;



//rafc
const MyNavbar = () => {

  const context = useContext(ArticleContext)
  const {

    viewMode,
    currentArticle,
    searchArticles,
    addChilds,
    addStored,
    newArticle,
    emptyArticle,
    switchViewMode,
  } = context;

  const authContext = useContext(AuthContext)
  const {
    token,
    user,
    isAuthencated,
    login,
    logout
  } = authContext;
      
  const history = useHistory();
  
  const [searchValues, setSearchValues] = useState({multiValue:[]});

      // const searchIncText = useRef('');
      
      // useEffect(() => {
        
        //    // searchIncText.current.focus();
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // },[currentArticle, searchTxt, searchArticles])
        // useEffect(() => {
          
          //     //setSearchMode("all")
          //     //searchIncText.current.focus();
          //     // eslint-disable-next-line react-hooks/exhaustive-deps
          // },[searchArticles])
          
          // const OnSearchList = () => {
            //     console.log(showSearchList)
            //     toggleSearchList();
            // }
            // const OnSearchMode = (key, e) => {   // childs, parents, stored, all
            
            //   setSearchMode(key) // dodać state.activeKey
            // }
            
            //const articleListItemClicked = (key, e) => {
    const articleListItemClicked = (value) => {
      
      console.log(JSON.stringify(value));
      setSearchValues( state => {
        return {
          multiValue: value
        };
      })
      //getArticle(value.id);
      if(value && value.length) {


        history.push(`/articles/${value[value.length-1].id}`);
        //getArticle(value[value.length-1].id);                 // ciągle potrzebne?
      }
    }
    
    
    const OnView = () => { switchViewMode(); }  // view/edit mode
    const OnStore = () => { if(currentArticle.length > 0) addStored( currentArticle[0]._id ); } // store current article/add stored as child
    
    const OnSave = () => {

      if(isAuthencated && !user && currentArticle.length > 0) { // only for root user

        console.log("OnSave: " + JSON.stringify(currentArticle[0]));
        //currentArticle[0].childs = currentArticle[0].childs.map(child => ( {...child, "type":""})); //(child) => ({id: child.id, "type":""}))
        (async () => await axios.post(`/api/articles/update/${currentArticle[0]._id}`, currentArticle[0], {headers: {'Content-Type': 'application/json'}}))()
        .then(result => {
            //success
            alert(`${(user ? user.name : "<?>")}` + " - Article has been saved!")
        })
        .catch(err => {
            
            alert("Article save error: " + JSON.stringify(err))
        })
      }

    } // only in edit mode
    
    const OnAddNewArt = () => { // add article at level below (subarticle, only for subarticles) 
      
      (async () => await axios.post(`/api/articles/add`, emptyArticle, {headers: {'Content-Type': 'application/json'}}))().then(resp => {
        
        console.log("addChilds:" + JSON.stringify( resp.data ));
        newArticle(resp.data)
        addChilds( [{type:"", id:resp.data._id}] );        
      })
      .catch(err => {
        
        alert("Add Sub Article error: " + JSON.stringify(err))
      })
    }
    
    const OnDelete = () => {  } // remove article (only for lowest level subarticles, remove locally - from current parent artice, warning when it's only one parent )
    const OnImages = () => {  } // image library for current article, uploading, removing images
    
    
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
              <a
              href="_CustomToggle"
              ref={ref}
              onClick={e => {
                //console.log("CustomToggle")
                e.preventDefault();
                onClick(e);
              }}
              >
        
        {children}
      </a>
    ));

    const OnLogout = ( e ) => {

      e.preventDefault();
      logout();
    }

    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: '#909090',
        color: '#000000',
        'max-height': "1.8rem",
        // match with the menu
        //border: "2px solid #BCE072",
        borderRadius: "1px",//state.isFocused ? "3px 3px 0 0" : 3,
        // Overwrittes the different states of border
        //borderColor: '#BCE072',
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          // Overwrittes the different states of border
          border: "1px solid #BCE072",
          //borderColor: state.isFocused ? '#BCE072' : '#BCE072'
        }
      }),
      menu: base => ({
        ...base,
        background: '#909090',
        color: '#000000',
        // override border radius to match the box
        //border: "2px solid #BCE072",
        borderRadius: '0px',
        // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
        hyphens: "auto",
        // kill the gap
        marginTop: 0,
        textAlign: "left",
        // prevent menu to scroll y
        wordWrap: "break-word",
        opacity: 0.95
      }),
      menuList: base => ({
        ...base,
        // kill the white space on first and last option
        padding: 0
      }),
      singleValue: (provided, state) =>  ({ ...provided,  color:"red", transition:'opacity 300ms'  })
    };
    
 

      return (
        <SNavbar fixed="top" variant="dark" className = "hide_when_horizontal"> 
        <SRow>    

        <SCol md = {11}>  
          <SRow>
            <LogoTitle>
                     <img width = "64" height = "64" src="lambda-256x256x32.png" alt="j"/>
            My Notebook </LogoTitle>  
            <SInputContainer id = "search-input" className = "ml-auto">
            <MySelect 
              name = "filters"
              placeholder = "Szukaj.."
              autoFocus = {true}
              styles={customStyles}

              onCreateNew = {(art) => console.log(art)}
              value = {searchValues.multiValue}
              options = {searchArticles.map(art => {return {value: art._id, label: art.title, id: art._id, key: art._id}})} 
              onChange = {articleListItemClicked} 
              isMulti = {true}
             
            />
            </SInputContainer>
            

            {/* <LogoComment>© DM 2019 Made with <span style={{"color":"red"}}>❤</span></LogoComment>  */}

        {viewMode ? ( 
          <Nav>
            
            {isAuthencated ? (<>
              <NavLink href= "/"><i className="fas fa-home"></i></NavLink>
              <NavLink href= "/">{`${user ? "Hello " + user.name : "<?>"}`}</NavLink>
              <span className="sm-hide">|</span>
              <NavLink href= "" onClick={OnLogout}>
              Logout
              <i className="fas fa-sign-out-alt"></i>
            </NavLink>
            <span className="sm-hide"> *** </span>
            <NavLink href="#!" onClick={OnView} ><i className="fas fa-edit"></i>
              <span className="sm-hide">Edit</span>
            </NavLink>
            </>) :
            (<>
              <NavLink href= "/"><i className="fas fa-home"></i></NavLink>
              <NavLink href= "/login">
              Login..
              <i className="fas fa-sign-in-alt"></i>
              </NavLink>
            </>
            )}

            <span className="sm-hide">|</span>
            <NavLink href="#!" onClick={OnStore} ><i className="fas fa-archive"></i>
            <span className="sm-hide">Store</span>
            </NavLink>

          </Nav>) :

          (<Nav>

            <NavLink href="#!" onClick={OnView} ><i className="fas fa-eye"></i>
              <span className="sm-hide">View</span>
            </NavLink>
            <span className="sm-hide">|</span>
            <NavLink href="#!" onClick={OnSave} ><i className="fas fa-save"></i>
              <span className="sm-hide">Save</span>
            </NavLink>
            <span className="sm-hide">|</span>
            <NavLink href="#!" onClick={OnAddNewArt} ><i className="fas fa-plus-circle"></i>
              <span className="sm-hide">New Article</span>
            </NavLink>
            <span className="sm-hide">|</span>
            <NavLink href="#!" onClick={OnDelete} ><i className="fas fa-trash"></i>
              <span className="sm-hide">Delete</span>
            </NavLink>
            <span className="sm-hide">|</span>
            <NavLink href="#!" onClick={OnImages} ><i className="fas fa-images"></i>
              <span className="sm-hide">Images</span>
            </NavLink>
            <span className="sm-hide">|</span>
            <NavLink href="#!" onClick={OnStore} ><i className="fas fa-archive"></i>
            <span className="sm-hide">Store</span>
            </NavLink>
          </Nav>)}
          </SRow>
        </SCol>
        </SRow>

        <Navigator/>

      </SNavbar>
    )
}

export default MyNavbar
//        <div className="navbar" style={{backgroundImage:'linear-gradient(to right, #9F714F, #342623'}}>