import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom';
import ArticleContext from '../../context/article/context'
import {appStyle, SRow, STabs, STab} from '../style'

import styled from 'styled-components';
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'


// display: inline-block;
// search list item
const SDropdownItem = styled(Dropdown.Item)`
  
    padding: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    // border: 2px solid ${appStyle.midGreen};
    margin-top: 4px;
    margin-bottom: 4px;
    background-color: ${appStyle.czubajMid};
    color:#563929;
    width: -webkit-fill-available;

    &.active {
        
        background-color: ${appStyle.czubajHighLight};
        color: #000000; /*AE674E*/        
    }

    :hover {
  
        background-color: ${appStyle.czubajLight};
        color: #000000;
    } 
    `;

    // export const SDropdownMiniItem = styled(Dropdown.Item)`
    
    //     height: 24px;
    //     margin: 0px;
    //     padding: 4px; 
    // `;
    
    // width: -webkit-fill-available;
const SDropdownButton = styled(DropdownButton)`
    font-size: 0.5rem;
    height: 20px;
    width: 20px;
    margin: 0px;
    padding: 0px; 
    border: 0px;
    display: inline;

`;



// Listo of:
// 1. Included articles (included child subarticle)
// 2. Child articles (related subarticle)
// 3. Farter Childs articles (childs of childs of..)

//${czubaj2Light};
   // border: 2px solid ${colorMidGreen};
    //   background-color: white; 
     //border: 2px solid ${appStyle.midDarkGreen};    

// childs/stored main container
const SRightContainer = styled(Container)`

    margin: 0;
    padding: 0;

    background-color:  ${appStyle.background};

    // border-radius: ${appStyle.appRadius};
    width: -webkit-fill-available; 
`;


    // display: inline-flex;
const SListGroup = styled(ListGroup)`

    padding-top: 0px;
    padding-buttom: 0px;
    border: 0px;    
    margin-top: 0px;
    margin-buttom: 0px;
    text-align: left;
    cursor: pointer;
    width: -webkit-fill-available;
`;


    // :hover {
  
    //     background-color: ${appStyle.czubajLight};
    //     color: #000000;
    // } 
  // display: inline-block;
  // child / stored list item
  //   border: 2px solid ${appStyle.midGreen}; 
  //   border: 2px solid ${appStyle.midLightGreen};  
const SListGroupItem = styled(ListGroup.Item)`

    padding: 0;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 8px;
    margin-bottom: 0px;
    margin-right: 0px;
    background-color: ${appStyle.background2};

    // border: 2px solid #c0a000;
    // border-radius: ${appStyle.appRadius};
    box-shadow: 2px 2px 10px rgba(0.5, 0.7, 0.9, 0.5);
    color:#563929;
    box-shadow: 0px 8px 8px -4px rgba(0.5, 0.7, 0.9, 0.7);
    width: -webkit-fill-available;

    &.active {
        
        background-color: ${appStyle.midLightGreen};
        color: #000000; /*AE674E*/        
    }
`;

    // childs item, stored item
    // font-style: italic;
const SLink = styled(Link)` /* childs list items */

    font-family: "Fantasque Sans Mono"; //ArbutusSlab Regular";
    font-weight: normal;
    font-size: 1.2rem;
    border: 0;
    padding: 0.4rem;
    margin: 0;
    background-color: ${appStyle.background2};
    color: #bbbb30;
    text-shadow: -1px -1px 2px rgba(0,0,0,0.3);
    text-align: left;
    width: -webkit-fill-available;
    display: block;
    // border-radius: 0.4rem;

    :hover {
  
        background-color:  rgba(0, 0, 0, 0.0);
        text-shadow: 4px 4px 8px  rgba(255,255,0,0.67);
        color: #ffff70;
    } 
`;




function ArticlesList() {
    
    const context = useContext(ArticleContext)
    const {
        viewMode,
        articles,
        currentArticle,
        searchArticles,
        storedArticles,
        searchMode,
        setSearchMode,
        addStored,
        removeStored,
        addChilds,
        removeChild,
        setChildType
    } = context;
    
    
    useEffect(() => {

        setSearchMode("childs")
    },[searchArticles])


    // const articleListItemClickedButton = ( e ) => {
        
    //     console.log("articleListItemClickedButton e.target:" + e.target)
    //     <Link to={`/articles/${e.target.id}`}> go to article</Link>
    //     //getArticle( e.target.id )
    // }
      
    const dropdownItemAddChilds = (key, e) => {

        console.log("dropdownItemAddChilds Clicked:" + key)
        if(storedArticles.length) {
    
           addChilds( [{type: "", id:key}] ); 
        }
        else {
      
           alert(`You have first add some article to "Stored" `)
        }
    }

    const dropdownItemRemFSClicked = (key, e) => {

        console.log("dropdownItemRemFSClicked:" + key)
        removeStored( key );
    }

    const dropdownItemRemFCClicked = (key, e) => {

        console.log("dropdownItemRemFCClicked:" + key)
        removeChild( key );
    }

    const dropdownItemStoreCClicked = (key, e) => {

        console.log("dropdownItemRemFCClicked:" + key)
        addStored( key );
    }
    const dropdownItemAddToParentAsPartClicked = (key, e) => {

        console.log("dropdownItemAddToParentAsPartClicked:" + key)
        setChildType({id: key, type: "PART"})
    }

    const dropdownItemAddToParentAsLinkClicked = (key, e) => {

        console.log("dropdownItemAddToParentAsLinkClicked:" + key)
        setChildType({id: key, type: "LINK"})
    }

    const OnSearchMode = (key, e) => {   // childs, parents, stored, all

        setSearchMode(key) // dodać state.activeKey
    }
 

    return (

        <SRightContainer>    
        {/* <Row><h5>Search Article:</h5></Row> */}

        <SRow>
            <STabs activeKey={searchMode} onSelect={OnSearchMode}> {/* now it's childs and stored tab */}
                <STab eventKey="childs" title="Powiązane">
                    <SListGroup> { 
                        
                        articles.filter( art => currentArticle.length &&art._id === currentArticle[0]._id).map(current => current.childs.map(child => {
                        let res = articles.filter(art => art._id === child.id);
                        return (
                            <SListGroupItem key={child.id}> {
                                !viewMode ? 
                                <SDropdownButton title="">
                                    <SDropdownItem eventKey={child.id} onSelect={dropdownItemAddToParentAsLinkClicked}>
                                        {child.type === "PART" || child.type === "" ? "Dodaj do art. jako link" : "Usuń podartykuł z art."}
                                    </SDropdownItem>
                                    <SDropdownItem eventKey={child.id} onSelect={dropdownItemAddToParentAsPartClicked}>
                                        {child.type === "LINK" || child.type === "" ? "Dodaj jako część art." : "Usuń podartykuł z art."}
                                    </SDropdownItem>
                                    <SDropdownItem eventKey={child.id} onSelect={dropdownItemRemFCClicked}>Usuń z listy podartykułów</SDropdownItem>
                                    <SDropdownItem eventKey={child.id} onSelect={dropdownItemStoreCClicked}>Wrzuć do schowka</SDropdownItem>
                                </SDropdownButton>
                                : 
                                <SDropdownButton title="">
                                    <SDropdownItem eventKey={child.id} onSelect={dropdownItemStoreCClicked}>Wrzuć do schowka</SDropdownItem>
                                </SDropdownButton>
                                 }
                                 <SLink to={`/articles/${res.length ? res[0]._id : "?"}`}>{res.length ? res[0].title : "?"}</SLink>
                                {/*<SListButton id = {res[0]._id} variant="primary" size="sm" onClick={articleListItemClickedButton}>
                                    {res.length? res[0].title : "?"}
                                </SListButton>*/}
                            </SListGroupItem>
                        )}))
                    }
                    </SListGroup>
                </STab>

                <STab eventKey="stored" title="Schowek">
                    <SListGroup> { storedArticles.map(articleId => {
                   
                        let res = articles.filter(art => art._id === articleId);
                        return (
                        
                            <SListGroupItem key={articleId}>{
                                !viewMode ? 

                                <SDropdownButton title="">
                                    <SDropdownItem eventKey={articleId} onSelect={dropdownItemAddChilds}>Dodaj do art. jako podartykuł</SDropdownItem>
                                    <SDropdownItem eventKey={articleId} onSelect={dropdownItemRemFSClicked}>Usuń ze schowka</SDropdownItem>
                                </SDropdownButton>
                                :
                                <SDropdownButton title="">
                                    <SDropdownItem eventKey={articleId} onSelect={dropdownItemRemFSClicked}>Usuń ze schowka</SDropdownItem>
                                </SDropdownButton>
                                }   
                                <SLink to={`/articles/${res.length? res[0]._id : "?"}`}>{res.length? res[0].title : "?"}</SLink>
                                {/*<SListButton id = {articleId} variant="primary" size="sm"  onClick={articleListItemClickedButton}>
                                {res.length? res[0].title : "?"} 
                            </SListButton>*/}

                            </SListGroupItem>
                        )})
                    }
                    </SListGroup>
                </STab>
            </STabs>
        </SRow>
        </SRightContainer>
    )
}
//<SDropdownButton as={ButtonGroup} title="" id="bg-nested-dropdown">
export default ArticlesList
