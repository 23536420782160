import React, { useContext, useEffect, useRef } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import ArticleContext from '../../context/article/context'
import {SInputFormControl} from '../style'

import hljs from 'highlight.js'
import javascript from 'highlight.js/lib/languages/javascript';
import haskell from 'highlight.js/lib/languages/haskell';
import fsharp from 'highlight.js/lib/languages/fsharp';
import ocaml from 'highlight.js/lib/languages/ocaml';
import lisp from 'highlight.js/lib/languages/lisp';
import php from 'highlight.js/lib/languages/php';



// padding-left: 1rem;
// padding-right: 1rem;
const ArticleContainer = styled(Container)`
    padding: 0;
    margin: 0;
    width: -webkit-fill-available; 
`;


    // display: block;
    // display: inline-flex;
    // flex-direction: column; 
    // width: fit-content;   


// width: fit-content;
// border: 1px solid ${appStyle.midGreen};    
export const ArticleCol = styled(Col)`

    margin: 0rem;
    padding: 0rem;

    border-radius: 1px;
    boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.25)'
`

const ArticleRow = styled(Row)`

    margin-left:0;
    margin-right:0;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color:#aaa;
    color:#000;
`

export const SContainer = styled.div `

    overflow:hidden; /*prevent to text overflow beyond column*/
    width: 100%;
    /* padding: 1rem; */
`


const EditorBase = (params) => {

  //const [articles, setArticles] = useContext(ArticleContext)
  const context = useContext(ArticleContext)
  const {

        viewMode,
        articles,
        currentArticle,
        setCurrentArticle,
  } = context;
  //var current_art;
  // component initialize

  hljs.highlightAll()
  hljs.registerLanguage('javascript', javascript);
  hljs.registerLanguage('fsharp', fsharp);
  hljs.registerLanguage('haskell', haskell);
  hljs.registerLanguage('ocaml', ocaml);
  hljs.registerLanguage('lisp', lisp);
  hljs.registerLanguage('php', php);

  //const highlightRef = useRef('');

  // MAX 1 ARTICLE AND 7 INCLUDED SUBARTICLES TO HIGHLIGHT
  const maxRefsNo = 7;
  const highlightRefs = useRef([...Array(maxRefsNo)].map(() => React.createRef())); //useRef(new Array(maxRefsNo+1));//React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()]);
 

  // useEffect(() => {

  //   getArticles();
  //  // clearSearchInc();
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ currentArticle ])  


  console.log('EditorBase: component will mount');

  // component did mount/updated
  useEffect(() => {

    console.log("EditorBase: init params.id:" + params.id)
    
     return () => {
       console.log('EditorBase: component will unmount');
     }

  }, []);


  useEffect(() => {

    //if (viewMode == 0) setCurrentArticle({...currentArticle[0], description: current_art}); // 2023-12-24
	  if(viewMode == 0) console.log("EditorBase (viewMode) viewMode == 0");
	  else console.log("EditorBase (viewMode) viewMode != 0");
    if(highlightRefs.current) {
    
      console.log("EditorBase highlightRefs.current **[2023-12-28]");

      highlightRefs.current.forEach(ref => {

        if(ref.current) {

          console.log("EditorBase (viewMode) highlightBlock")
          const nodes = ref.current.querySelectorAll('code');
          nodes.forEach((node) => {
              hljs.highlightElement(node);
          });
        }
      })
    }
    return () => {
    
       console.log('EditorBase (viewMode) will unmount');
    };
	  //else 
    // if(current_art) setCurrentArticle({...currentArticle[0], description: current_art}); // 2023-12-24
  }, //[currentArticle, viewMode]);
[viewMode]);




  const OnArticleDescriptionChange = ( e ) => {
      
    console.log(e.target.value);
    setCurrentArticle({...currentArticle[0], summary: e.target.value});
  };
    
  // const OnArticleChildsChange = ( e ) => {
      
  //   console.log(e.target.value)
  //   setCurrentArticle({...currentArticle[0], shortname: e.target.value})
  // }


    //Article Editor change
  const handleEditorChange = (content, editor) => {
    //current_art = content;
    console.log("editor change");
    setCurrentArticle({...currentArticle[0], description: content}) // 2023-12-24
  }




  
  return (
    
    <ArticleContainer className="main">  

      {viewMode ? (<SContainer> 
      <base href="https://daryo.pl/"/>
      <ArticleRow> 
        <ArticleCol md={12} ref={ highlightRefs.current[0] } dangerouslySetInnerHTML={{__html: currentArticle.length ? currentArticle[0].description: "?"}}>
        </ArticleCol>
      </ArticleRow>

      <ArticleRow > 
        <ArticleCol md={12}>
          {currentArticle.length && currentArticle[0].childs ? ( 

            currentArticle[0].childs.map((child, i) => {

              let res = articles.filter(art => art._id === child.id && child.type === "PART");
              return (
                <div ref={ highlightRefs.current[i<maxRefsNo ? i+1 : maxRefsNo]  } dangerouslySetInnerHTML={{__html: res.length ? res[0].description : ""}} />
              )
            })
          ) : (<div></div>)}
         
        </ArticleCol>
      </ArticleRow>
      </SContainer>) : (<SContainer>

      <ArticleRow>
        <ArticleCol md={12}>
          <SInputFormControl  type='text' value = {currentArticle.length ? currentArticle[0].summary : "?"} onChange = {OnArticleDescriptionChange} placeholder='Summary'/>
        </ArticleCol> 
      </ArticleRow> 

      <ArticleRow> 
        <ArticleCol md={12}>
        <Editor 
          initialValue={currentArticle.length ? currentArticle[0].description : "Empty articles set?"}
          value ={currentArticle.length ? currentArticle[0].description: ""}
          //apiKey="x28mohxs74i6o8fsata7b2nwzo6bjl6bhqa6nxudecvrod69"
          apiKey="gtuoq2ngfkffuxefdortijvau5unahsvr1lojb51qin1kl3c"
          init={{
            selector: 'textarea',
            content_css : '../src/App.css',
            menubar: true,
            statusbar: true,
            height: 700,
            // width : 1200,

            plugins: [
              'advlist autolink lists link image charmap print preview hr anchor pagebreak',
              'searchreplace wordcount visualblocks visualchars code fullscreen',
              'insertdatetime media nonbreaking save table directionality',
              'emoticons template paste textpattern'
            ],
            toolbar1: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
            toolbar2: 'print preview media | forecolor backcolor emoticons',
            image_advtab: true, //false,
            paste_auto_cleanup_on_paste : true,
            apply_source_formatting:true,
            entity_encoding: 'named',
            entities:'169,copy, 8482,trade, 8211, ndash, 8212,mdash, 8216,lsquo, 8217,rsquo, 8220,ldquo, 8221,rdquo, 8364,euro',
            relative_urls: true,
            br_in_pre:true, // true default
            convert_newlines_to_brs: false,            
            forced_root_block: true,
            remove_linebreaks: false, //true,            
            //auto_focus: 'element1',
            
            templates : [
              {
              title: "Abbreviation",
              content: '<abbr title="rolling on the floor, laughing out loud">That joke had me ROTFLOL </abbr>',
              description: "Abbreviation"
            }
          ]
        }}
        onEditorChange={handleEditorChange}
        />
      </ArticleCol>
    </ArticleRow>

    <ArticleRow> 
      <ArticleCol md="12">{

          currentArticle.length ? ( 
        
            currentArticle[0].childs.map(child => {

            let res = articles.filter(art => art._id === child.id && child.type === "PART");
            return (
                <div dangerouslySetInnerHTML={{__html: res.length ? res[0].description : ""}} />
            )
            })

        ) : (<div></div>)
      }
      </ArticleCol>
    </ArticleRow>
    </SContainer>)}

  </ArticleContainer>
  )
}

/* <Col md={4}>
  <FormCheck.Input type='text' value = {currentArticle.shortname} onChange = {OnArticleChildsChange} placeholder='Childs' style={styles.inputStyle}/>
</Col>   */
//value ={(articles && articles.length)? articles[0]._id : ""}
//initialValue={(articles && articles[0].length) ? articles[0].description : "Empty articles set?"}
//   onChange={handleEditorChange}
export default EditorBase;
