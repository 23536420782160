import React from 'react';
import { Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import '@fortawesome/fontawesome-free'
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/brands.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import Navbar from './components/layouts/Navbar'
import Article from './components/pages/Article'
import Register from './components/pages/Register'
import Login from './components/pages/Login'

import {ArticleStateProvider} from './context/article/context'
import {AuthStateProvider} from './context/auth/context'




// import 'highlight.js/styles/solarized-light.css';
import 'highlight.js/styles/default.css';
import './App.css';

// npm install --save bootstrap
// npm install --save-dev @fortawesome/fontawesome-free
// npm install --save @tinymce/tinymce-react
// dev server: http://localhost:8181
// client "proxy": "https://mynote-book.herokuapp.com/"
//yarn dlx serve -s build

function App() {

  return (
    <AuthStateProvider>
      <ArticleStateProvider>      
        <Navbar />
        <Switch>
          <Route exact path="/" component = {Article} />
          {true && <Route path="/articles/:id" component = {Article}/>}
          <Route exact path="/about" render={() => <div> <h1>about..</h1><h1>about..</h1><h1>about..</h1></div>} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/login' component={Login} />          
          <Route path="/" render={() => <div> <h1>404..</h1><h1>404..</h1><h1>404.. Where I am? What I'm doing here?</h1></div>} /> 
        </Switch>        
      </ArticleStateProvider>   
    </AuthStateProvider>
  );
}

export default App;
